import { atom, selector, selectorFamily } from "recoil";

export const priceModelIdAtom = atom({
	key: "priceModelIdAtom",
	default: null,
});

export const priceModelCreationModalAtom = atom({
	key: "priceModelCreationModalAtom",
	default: false,
});

export const gatherAttributesInPriceModelModalAtom = atom({
	key: "gatherAttributesInPriceModelModalAtom",
	default: false,
});

export const priceModelSelectedFeaturesAtom = atom({
	key: "priceModelSelectedFeatures",
	default: [],
});

export const priceModelSelectedLevelsAtom = atom({
	key: "priceModelSelectedLevels",
	default: new Map(),
});

export const priceModelCategoryToAddInAtom = atom({
	key: "priceModelCategoryToAddIn",
	default: null,
});

export const priceModelSectionInCreationAtom = atom({
	key: "priceModelSectionInCreation",
	default: false,
});

export const priceModelShowDeleteAttributeModalAtom = atom({
	key: "priceModelShowDeleteAttributeModal",
	default: false,
});

export const priceModelShowAttributeModalAtom = atom({
	key: "priceModelShowAttributeModal",
	default: {
		visible: false,
		type: "",
		featureId: null,
		priceModelFeatureId: null,
	},
});

const refreshHashReloadAtom = atom({
	key: "refreshHashReloadAtom",
	default: false,
});

export const refreshHashReloadSelector = selector({
	key: "refreshHashReloadSelector",
	get: ({ get }) => get(refreshHashReloadAtom),
	set: ({ set }) => {
		set(refreshHashReloadAtom, prev => !prev);
	},
});

export const resetPriceModelCategories = atom({
	key: "resetPriceModelCategories",
	default: false,
});

export const hasMoreCategoriesAtom = atom({
	key: "hasMoreCategories",
	default: true,
});

export const hasMoreFeaturesAtom = atom({
	key: "hasMoreFeatures",
	default: {},
});

export const toRenamePriceModelIdAtom = atom({
	key: "toRenamePriceModelId",
	default: null,
});

export const indicatorsSectionCollapsedAtom = atom({
	key: "indicatorsSectionCollapsedAtom",
	default: true,
});

export const showSearchFeatureAtom = atom({
	key: "showSearchFeatureAtom",
	default: false,
});

export const searchAttributeNameAtom = atom({
	key: "searchAttributeNameAtom",
	default: "",
});

export const sectionToDeleteAtom = atom({
	key: "sectionToDeleteAtom",
	default: null,
});

export const priceModelFeatureToDeleteAtom = atom({
	key: "priceModelFeatureToDeleteAtom",
	default: null,
});

export const fullscreenAttributeCreationDrawerAtom = atom({
	key: "fullscreenAttributeCreationDrawerAtom",
	default: false,
});

export const showAdvancedAttributeConfigurationAtom = atom({
	key: "showAdvancedAttributeConfigurationAtom",
	default: false,
});

export const currentSectionLineOffsetAtom = atom({
	key: "currentSectionLineOffsetAtom",
	default: 0,
});

export const showPropertiesRuleAtom = atom({
	key: "showPropertiesRuleAtom",
	default: false,
});

export const dndItems = atom({
	key: "dndItems",
	default: {},
});

export const priceModelGenFeaturesDescriptionsOptionsAtom = atom({
	key: "priceModelGenFeaturesDescriptionsOptionsAtom",
	default: {
		maxSize: "50",
		content: ["benefits"],
		context: [],
		customPrompt: "",
		genLevelsDescriptions: false,
		language: "en",
		enableLanguage: false,
	},
});

export const priceModelGenLevelsDescriptionsOptionsAtom = atom({
	key: "priceModelGenLevelsDescriptionsOptionsAtom",
	default: {
		maxSize: "50",
		content: ["benefits"],
		context: [],
		customPrompt: "",
		language: "en",
		enableLanguage: false,
	},
});

export const enableCustomPromptAtom = atom({
	key: "enableCustomPromptAtom",
	default: false,
});

export const levelIndexAtom = atom({
	key: "levelIndexAtom",
	default: {},
});

export const levelIndexFeatureAtomFamily = selectorFamily({
	key: "levelIndexFeatureAtomFamily",
	get:
		featureId =>
		({ get }) => {
			return get(levelIndexAtom)[featureId];
		},
	set:
		featureId =>
		({ get, set }, newValue) => {
			set(levelIndexAtom, {
				...get(levelIndexAtom),
				[featureId]: newValue,
			});
		},
});

export const levelIndexAtomFamily = selectorFamily({
	key: "levelIndexAtomFamily",
	get:
		({ packId, featureId }) =>
		({ get }) => {
			return get(levelIndexAtom)[featureId]?.[packId];
		},
	set:
		({ packId, featureId }) =>
		({ get, set }, newValue) => {
			set(levelIndexAtom, prevState => ({
				...prevState,
				[featureId]: {
					...prevState[featureId],
					[packId]: newValue,
				},
			}));
		},
});

export const indicatorsBarChartsModalVisibleAtom = atom({
	key: "indicatorsBarChartsModalVisibleAtom",
	default: false,
});

export const priceModelCategoryCollapsedAtom = atom({
	key: "priceModelCategoryCollapsedAtom",
	default: {},
});

export const categoryCollapsedSelector = selectorFamily({
	key: "categoryCollapsedSelector",
	get:
		({ priceModelId, categoryId }) =>
		({ get }) => {
			const state = get(priceModelCategoryCollapsedAtom);
			return state[priceModelId][categoryId] || false;
		},
	set:
		({ priceModelId, categoryId }) =>
		({ set }, newValue) => {
			set(priceModelCategoryCollapsedAtom, prevState => ({
				...prevState,
				[priceModelId]: {
					...prevState[priceModelId],
					[categoryId]: newValue,
				},
			}));
		},
});

export const categoryCollapsedPriceModelSelector = selectorFamily({
	key: "categoryCollapsedSelector",
	get:
		priceModelId =>
		({ get }) => {
			const state = get(priceModelCategoryCollapsedAtom);
			return state[priceModelId];
		},
	set:
		priceModelId =>
		({ set }, newValue) => {
			set(priceModelCategoryCollapsedAtom, prevState => ({
				...prevState,
				[priceModelId]: newValue,
			}));
		},
});
export const isFullscreenModeAtom = atom({
	key: "isFullscreenModeAtomForPriceModelPage",
	default: false,
});

export const priceModelPageRefElAtom = atom({
	key: "priceModelPageRefElAtomForPriceModelPage",
	default: undefined,
});

export const isValueMapFullScreenAtom = atom({
	key: "isValueMapFullScreenAtom",
	default: false,
});
